var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RAsyncPagedTable',{attrs:{"data":_vm.data.results,"columns":_vm.columns,"page":_vm.page,"page-size":_vm.pageSize,"total-records":_vm.data.count},on:{"fetch":function($event){return _vm.$emit('fetch')},"page-change":function($event){return _vm.$emit('page-change', $event)}},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"heavy"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.published,'MMM dd, yyyy'))+" ")]),_c('div',{staticClass:"faded"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.published,'h:mm a'))+" ")])])]}},{key:"bed-bath",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.bedroom_count ? ((item.bedroom_count) + "BD") : '-')+" / "+_vm._s(item.bathroom_count ? ((item.bathroom_count) + "BA") : '-')+" ")])]}},{key:"title",fn:function(ref){
var item = ref.item;
return [_c('td',[(_vm.isFailed(item.status))?[_c('span',{staticClass:"faded"},[_vm._v(_vm._s(item.title))])]:(item.view_url)?[_c('a',{attrs:{"target":"_blank","href":item.view_url}},[_vm._v(_vm._s(item.title))])]:[_vm._v(" "+_vm._s(item.title)+" ")]],2)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.status === 'deleted')?_c('RBadge',{attrs:{"color":"secondary"}},[_vm._v(" Deleted ")]):_vm._e(),(item.status === 'expired')?_c('RBadge',{attrs:{"color":"secondary"}},[_vm._v(" Expired ")]):_vm._e(),(item.status === 'flagged')?_c('RBadge',{attrs:{"color":"danger"}},[_vm._v(" Flagged ")]):_vm._e(),(item.status === 'payment_needed')?_c('RBadge',{attrs:{"color":"danger"}},[_vm._v(" Payment Needed ")]):_vm._e(),(item.status === 'phone_confirmation_needed')?_c('RBadge',{attrs:{"color":"warning"}},[_vm._v(" Phone Verification ")]):_vm._e(),(item.status === 'email_confirmation_needed')?_c('RBadge',{attrs:{"color":"warning"}},[_vm._v(" Email Verification ")]):_vm._e(),(item.status === 'post_volume_limit_reached')?_c('RBadge',{attrs:{"color":"warning"}},[_vm._v(" Posting Limit Reached ")]):_vm._e(),(item.status === 'posting_too_rapidly_reached')?_c('RBadge',{attrs:{"color":"warning"}},[_vm._v(" Posting Too Rapidly Reached ")]):_vm._e(),(item.reposted)?_c('RBadge',{attrs:{"color":"secondary"}},[_vm._v(" Reposted ")]):_vm._e(),(item.manual_post)?_c('RBadge',{attrs:{"color":"primary"}},[_vm._v(" Posted Manually ")]):_vm._e(),(item.posted_through_caas)?_c('RBadge',{attrs:{"color":"info"}},[_vm._v(" Posted by Rooof ")]):_vm._e()],1)]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatPrice(item.price))+" ")])]}},{key:"renew_count",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.renew_count ? item.renew_count : '-')+" ")])]}},{key:"email_leads",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.email_leads ? item.email_leads : '-')+" ")])]}},{key:"redirect_count",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.redirect_count ? item.redirect_count : '-')+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }